/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  z-index: 997;
  background: #fff;
}

.header.header-scrolled {
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.navbar {
  transition: all .3s ease;
}

.navbar-collapse {
  padding-bottom: 1.4rem;
}

@media (min-width:280px) and (max-width:1000px) {

  .navbar-collapse {
    border: 1px solid #716e6e;
    box-shadow: 0px 2px 20px rgba(57, 59, 63, 0.1);
  }
  .nav-login{
    margin-left: 30px;
  }
}

.navbar.navbar-expand-lg.navbar-light {
  padding-top: 10px;
  padding-bottom: 0;
}

.header.header-scrolled .navbar.navbar-expand-lg.navbar-light {
  padding-top: 5px;
  padding-bottom: 0;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
a.btn-primary-caas,
a.btn-primary-caas:focus,
a.btn-primary-caas:hover,
.info-box .btn-primary-caas,
.info-box .btn-primary-caas:focus,
.info-box .btn-primary-caas:hover {
  color: #fff;
  text-decoration: none;
}

a.btn-primary-caas:active,
.info-box .btn-primary-caas:active  {
  background-color: #970c55;
  border-color: #970c55;
  color: #fff;
}

a.btn-primary-caas:focus,
a.btn-primary-caas:hover,
.info-box .btn-primary-caas:focus,
.info-box .btn-primary-caas:hover  {
  background-color: #970c55;
  border-color: #970c55;
  color: #fff;
}

@media (min-width: 992px){
  .hide-lg {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hide-sm {
    display: none;
  }
}

.navbar-collapse .dropdown-menu {
  min-width: 8.3rem;
}

.nav-link {
  margin: 5px 30px;
    font-weight: 700 !important;
}

.nav-link.active {
  border-bottom: solid;
    border-width: 3px !important;
}

.nav-item a.nav-link {
  cursor: pointer;
}
